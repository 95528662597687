import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import Routes from './routes';
// import { Amplify } from 'aws-amplify';

// import awsconfig from './aws-exports';
// Amplify.configure(awsconfig);

const App: React.FC = () => (
  <>
    <Router>
      <Routes />
    </Router>
  </>
);

export default App;