import React from 'react';

import { Container, FileInfo } from './styles';

interface FileListProps {
  files: Array<{
    fileName: string;
    url: string;
  }>;
}

const DownloadList: React.FC<FileListProps> = ({ files }: FileListProps) => {
  return (
    <Container>
      {files.map(donloadFile => (
        <li key={donloadFile.fileName}>
          <FileInfo>
            <div>
              <strong>{donloadFile.fileName}</strong>
              <a href={donloadFile.url}>Download</a>
            </div>
          </FileInfo>
        </li>
      ))}
    </Container>
  );
};

export default DownloadList;
