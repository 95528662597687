import React from 'react';

import { Switch, Route } from 'react-router-dom';

import UpdateRNC from '../pages/fillRnc';
import UpdateCss from '../pages/fillCss';
import Home from '../pages/home';

const Routes: React.FC = () => (
  <Switch>

    <Route path="/rnc/:id" component={UpdateRNC} />
    <Route path="/css/:id" component={UpdateCss} />
  </Switch>
);

export default Routes;
