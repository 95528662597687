import React from 'react';
import { Container } from './styles';

interface SectionProps {
  text: string;
}

const Section: React.FC<SectionProps> = ({ text }: SectionProps) => {
  return (
    <Container>
      <p>{text}</p>
    </Container>
  )
};

export default Section;