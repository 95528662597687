import styled from 'styled-components';



export const Container = styled.div`
  display: flex;
  width: 100%;
  padding-top: 10px;


  p {
    width: 100%;
    text-align: center;
    margin: 60px 0 60px 0;
    font-size: 2em;

    // background: linear-gradient(90deg, rgba(121,83,159,0) 0%,#96B0C0 50%, rgba(27,21,52,0) 100%);
    background-repeat: no-repeat;
    background-size: 400px 10%;
    background-position: bottom;
    background-radious: 10px;
  }
`
