import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useContext
} from 'react';
import { IconBaseProps } from 'react-icons';
import { FiAlertCircle } from 'react-icons/fi';
import { useField } from '@unform/core';
import { dataContext } from '../../pages/fillCss'

import { Container, Error, Label, ContainerField } from './styles';

interface ButtonProps extends InputHTMLAttributes<HTMLInputElement> {
  LabelName: string;
  isFillable: boolean;
  isHalf: boolean;
  name: string;
  options: Array<{
    id: string;
    name: string;
    value: string;
  }>;
  icon: React.ComponentType<IconBaseProps> | null;
  colorOptionalDefaul?: 'optional' | 'api';
}

const Rate: React.FC<ButtonProps> = ({
  LabelName,
  isHalf,
  isFillable,
  name,
  options,
  icon: Icon,
  colorOptionalDefaul,
  ...rest
}: ButtonProps) => {
  const answerRef = useRef<HTMLInputElement[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const { data, setData } = useContext(dataContext);


  const handleButtonFocus = useCallback(() => {
    setIsFocused(true);
    console.log("Focus ", answerRef)
  }, []);

  const handleButtonBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!answerRef.current);
    console.log("Blur ", answerRef)
  }, []);

  const handleSaveResult = useCallback(() => {
    console.log("status setting:",name);
    console.log("current Data:",data);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: answerRef.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value || '';
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        const answerRef = refs.find(ref => ref.id === id);
        if (answerRef) answerRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const answerRef = refs.find(ref => ref.checked === true);
        if (answerRef) answerRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <>
      <ContainerField>
        <Label isHalf={isHalf}>{LabelName}:</Label>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable}>
          {Icon && <Icon size={20} />}
          {options.map((option, index) => (
            <div className={
              parseInt((option.value).split('/')[2]) < 4 ? "red" :
                parseInt((option.value).split('/')[2]) < 8 ? "yellow" : "green"
            }>
              <input
                ref={ref => ref && (answerRef.current[index] = ref)}
                id={option.id}
                type="radio"
                name={name}
                value={option.value}
                onFocus={handleButtonFocus}
                onBlur={handleButtonBlur}
                onChange={handleSaveResult}
                onClick={handleSaveResult}
                {...rest}
              />
              <label className={"radioLabel"} htmlFor={option.id}>
                <span>{(option.value).split('/')[2]}</span>
              </label>
            </div>
          ))}
          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};
export const SimpleRate: React.FC<ButtonProps> = ({
  LabelName,
  isHalf,
  isFillable,
  name,
  options,
  icon: Icon,
  colorOptionalDefaul,
  ...rest
}: ButtonProps) => {
  const answerRef = useRef<HTMLInputElement[]>([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);

  const { fieldName, defaultValue, error, registerField } = useField(name);
  const { data, setData } = useContext(dataContext);


  const handleButtonFocus = useCallback(() => {
    setIsFocused(true);
    console.log("Focus ", answerRef)
  }, []);

  const handleButtonBlur = useCallback(() => {
    setIsFocused(false);

    setIsFilled(!!answerRef.current);
    console.log("Blur ", answerRef)
  }, []);

  const handleSaveResult = useCallback((e:any) => {

    console.log("target question:",e.target.value.split('/')[1]);
    console.log("answer:",e.target.value.split('/')[2]);

    if(e.target.value.split('/')[1] === "C_C_CustomerSurveyQuestion1"){
      setData({...data, nps1:e.target.value.split('/')[2]});
    }
    else{
      setData({...data, nps2:e.target.value.split('/')[2]});
    }

    console.log(data);
  }, [data]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: answerRef.current,
      getValue: (refs: HTMLInputElement[]) => {
        return refs.find(ref => ref.checked)?.value || '';
      },
      setValue: (refs: HTMLInputElement[], id: string) => {
        const answerRef = refs.find(ref => ref.id === id);
        if (answerRef) answerRef.checked = true;
      },
      clearValue: (refs: HTMLInputElement[]) => {
        const answerRef = refs.find(ref => ref.checked === true);
        if (answerRef) answerRef.checked = false;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <>
      <ContainerField>
        <Container error={!!error} isFilled={isFilled} isFocused={isFocused} isFillable={isFillable}>
          {Icon && <Icon size={20} />}
          {options.map((option, index) => (
            <div className={
              parseInt((option.value).split('/')[2]) < 4 ? "red" :
                parseInt((option.value).split('/')[2]) < 8 ? "yellow" : "green"
            }>
              <input
                ref={ref => ref && (answerRef.current[index] = ref)}
                id={option.id}
                type="radio"
                name={name}
                value={option.value}
                onFocus={handleButtonFocus}
                onBlur={handleButtonBlur}
                onChange={(e)=>handleSaveResult(e)}
                {...rest}
              />
              <label className={"radioLabel"} key={option.id} htmlFor={option.id}>
                <span>{(option.value).split('/')[2]}</span>
              </label>
            </div>
          ))}
          {error && (
            <Error title={error}>
              <FiAlertCircle size={20} color="#c53030" />
            </Error>
          )}
        </Container>
      </ContainerField>
      <br />
    </>
  );
};

export default Rate;
