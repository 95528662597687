import styled from 'styled-components';

interface ContainerProps {
  size?: 'small' | 'large';
}

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.header};

  header {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    padding: ${({ size }) => (size === 'small' ? '40px 20px' : '40px 20px 150px')};
    display: flex;
    align-items: center;
    justify-content: space-between;

    nav {
      display: flex;
      align-items: center;
      justify-content: space-between;

      a {
        color: ${props => props.theme.colors.textHeader};
        text-decoration: none;
        font-size: 16px;
        transition: opacity 0.2s;

        & + a {
          margin-left: 32px;
        }

        &:hover {
          opacity: 0.6;
        }
      }
    }

      .logo{
        margin: 18px;
      }
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 15px;
  padding-right: 2px;
`;

export const Content = styled.div`
  position: fixed;
  top: 8px;
  right: 16px;
  background: rgba(150, 176, 192, 0.5);
  color: #f1f1f1;
  width: 100 %;
  padding: 10px;

  button{
    font-size:5px;
    padding: 3px;
    margin: 2px;
  }
`;
