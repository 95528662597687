export default {
  title: 'dark',

  colors: {
    primary: '#1B1534',
    secondary: '#27B66D',

    background: 'radial-gradient(circle, rgba(77,33,122,1) 7%, rgba(27,21,52,1) 48%)',
    inputBackground: 'transparent',
    inputBackgroundFillable: '#FFFFFF',

    header: 'transparent',

    text: '#FFFFFF',
    textHeader: '#FFFFFF',
    textInsideInput: '#000000',

    borderActive: '#0084E1',
    borderError: '#E85960',
    borderInactive: '#8A00E5',  

    checkSuccess: '#27B66D',
    checkError: '#E85960',

    siemensPetrol: '#009999',

    redButton: '#E85960',
    yellowButton: '#FFF91F',
    greenButton: '#00FD79',
  },
}