import YouTube from 'react-youtube';
import styled from 'styled-components';

export const Content = styled.div`
  position: fixed;
  top: 8px;
  right: 16px;
  background: rgba(150, 176, 192, 0.5);
  color: #f1f1f1;
  width: 100 %;
  padding: 10px;

  button{
    font-size:10px;
    padding: 3px;
    background: transparent;
    border: 0;

    &:hover {
      background: white;
    }
  
  }


`;
