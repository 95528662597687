import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary, Container, Footer, Title, Subtitle, Text, CSSContainer, Button, FooterContainer, FooterErrorContainer } from './styles';
import { DefaultTheme, ThemeProvider } from 'styled-components';

import styled from 'styled-components';

import * as Yup from 'yup';
import { useTranslation } from "react-i18next";

import {SimpleTextArea} from '../../components/TextArea';
import getValidationErrors from '../../utils/getValidationErrors';
import api from '../../services/api';
import addDays from '../../utils/addDays';
import Header from '../../components/Header';
import AnimatedIcon from '../../components/AnimatedIcon';
import {SimpleRate} from '../../components/Rate'

import GlobalStyle from '../../styles/global';
import dark from '../../styles/themes/dark';
import light from '../../styles/themes/light';
import Section from '../../components/Section';
import isEmpty from '../../utils/isEmpty';



// import { spacing } from "@mui/system";
import {
  Card as MuiCard,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)`
  background-color: transparent !important;
`;

// state dos dados trabalhados no processo
type DatasProps = {
  data:
    | {
        nps1: any | undefined;
        nps1_comment: any | undefined;
        nps2: any | undefined;
        nps2_comment: any | undefined;
        nps3: any | undefined;
      }
    | undefined;
  setData: (currentData: any) => void;
};
const initialData: DatasProps = {
  data: {
        nps1: undefined,
        nps1_comment: undefined,
        nps2: undefined,
        nps2_comment: undefined,
        nps3:undefined,
  },
  setData: () => {},
};
export const dataContext = React.createContext<DatasProps>(initialData);

// interfaces

interface fillCSS { // interface de preenchimento
  id: string;
  CSS: {
    id: string;
    C_Project: {
      id: string;
      Name: string;
    };
    C_Client: {
      id: string;
      Name: string;
    };
    C_RelatedProject: {
      C_ScopeDescription: string;
    };
    C_Comments: string;
  }
}
interface answeredCss { // interface de validação
  id: string;
  CreatedBy: {
    Name: string;
  }
  C_RelatedContact: {
    Name: string;
    C_PreferredLanguageSurvey?: {
      Name: "Spanish" | "English" | "Portuguese" | null;
    }; 
  }
  C_AlreadyAnswered: Boolean | null;
  CreatedOn: string;
}

interface updateCSS { // interface de atualização
  id: string;
  question1: number;
  question2: number;
  comments: string;
}


const TwoneCSS: React.FC = () => {
  // funcionamento do darkmode
  const [Theme, setTheme] = useState<DefaultTheme>(light);
  const toggleTheme = () => {
    setTheme(Theme.title === 'light' ? dark : light)
  }

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const { id } = useParams<any>();
  const formRef = useRef<FormHandles>(null);
  const [InfoCSS, setInfoCSS] = useState<fillCSS>({} as fillCSS);
  const [AnsCSS, setAnsCSS] = useState<answeredCss>({} as answeredCss);
  const [Answered, setAnswered] = useState<Boolean>(false); // guarda se o relatório já foi respondido
  const [IsExpired, setIsExpired] = useState<Boolean>(false); // guarda se está expirado
  const [IsSuccessfull, setIsSuccessfull] = useState<Boolean>(false); // guarda se o Report foi respondido corretamente
  const [IsError, setIsError] = useState<Boolean>(false); // guarda se ocorreu algum erro
  const [Found, setFound] = useState<Boolean>(); // guarda se foi encontrado o Report

  const [Disabled, setDisabled] = useState<any>(false);

  const [ShowWarning, setShowWarning] = useState<any>(false);


    const initData = {
        nps1: null,
        nps1_comment: null,
        nps2: null,
        nps2_comment: null,
        nps3:null,
    }
  const [data, setData] = useState(initData);
  const dataValue = {
    data,
    setData,
  };

  useEffect(() => {
    setShowWarning(false);
    document.title = "TW ONE - Customer Satisfaction Survey"
    async function loadTransactions(): Promise<void> {
      // Faz uma busca no Clarizen e verifica se a pesquisa é válida(já respondido, data de expiração)
      try {
        const answeredResp = await api.get(`/css-info/${id}`);
        const ansResp: answeredCss = answeredResp.data;
        console.log(ansResp);

        if (ansResp.C_RelatedContact.C_PreferredLanguageSurvey) {
          switch (ansResp.C_RelatedContact.C_PreferredLanguageSurvey.Name) {
            case 'English':
              changeLanguage('en')
              break;
            case 'Spanish':
              changeLanguage('es')
              break;
            case 'Portuguese':
              changeLanguage('pt')
              break;
            default:
              changeLanguage('es')
              break;
          }
        }
        setAnsCSS(ansResp);
        if (!answeredResp.data.hasOwnProperty('error')) {
          setFound(true);
          const cssAnsweredResp: answeredCss = answeredResp.data;

          // set data de expiração
          let limitDate: Date = addDays(
            new Date(cssAnsweredResp.CreatedOn),
            30
          );
          let today: Date = new Date();

          setAnswered(false);

          // Desativar temporariamente condição para verificar se está expirado
          // if (cssAnsweredResp.C_AlreadyAnswered !== true && !(today.getTime() > limitDate.getTime())) {
          //   const response = await api.get(`/css/${id}`);
          //   const cssResp: fillCSS = response.data;
          //   setInfoCSS(cssResp);
          // }


          if (cssAnsweredResp.C_AlreadyAnswered !== true) {
            const response = await api.get(`/css/${id}`);
            const cssResp: fillCSS = response.data;
            setInfoCSS(cssResp);
          }

          // valida se está expirado ou não
          else if (today.getTime() > limitDate.getTime()) {
            
          // Desativar temporariamente condição para verificar se está expirado
            setIsExpired(false);
            // setIsExpired(false);
          }

          // valida se já está respondido
          if (cssAnsweredResp.C_AlreadyAnswered === true) {
            setAnswered(cssAnsweredResp.C_AlreadyAnswered)
          }
        } else {
          setFound(false);
        }
      } catch (err) {
        setFound(false);
      }
    }

    loadTransactions();
  }, [id]);



  const handleSubmit = useCallback(
    async (ref:updateCSS) => {
      setDisabled(true); //  botão de enviar torna-se disabled após submit
      console.log("submit");

      try {
        // usa-se Yup para validação dos campos
        formRef.current?.setErrors({});

        var schemaObj : any = {
          question1: Yup.string().required(t("WarningMandatoryField")),
          question2: Yup.string().required(t("WarningMandatoryField")),
          question3: Yup.string().required(t("WarningComments")).max(2000, t("WarningMaxChar")),
        }

        if(data.nps1 !== null && (data.nps1 <=5 || data.nps1 >=8)){
          schemaObj.comment1 = Yup.string().required(t("WarningComments")).max(2000, t("WarningMaxChar"))
        }

        if(data.nps2 !== null && data.nps2 <=5){
          schemaObj.comment2 = Yup.string().required(t("WarningComments")).max(2000, t("WarningMaxChar"))
        }

        const schema = Yup.object().shape(schemaObj);

        await schema.validate(ref, {
          abortEarly: false,
        });


        let npsObj = {
          id: InfoCSS.CSS.id,
          nps1: data.nps1 === null ? null : parseFloat(data.nps1),
          nps1_comment: data.nps1 !== null && (data.nps1 > 5 && data.nps1 < 8) ? null : data.nps1_comment,
          nps2: data.nps2 === null ? null : parseFloat(data.nps2),
          nps2_comment: data.nps2 !== null && (data.nps2 > 5) ? null : data.nps2_comment,
          nps3: data.nps3,
        }

        console.log(npsObj);

        const respUpdate = await api.post('/css', npsObj);
        console.log(respUpdate);

        if (!respUpdate.data.hasOwnProperty('errorCode') && !respUpdate.data.responses[0].body.hasOwnProperty('errorCode')) {
          setIsSuccessfull(true);
        } else {
          setIsError(true);
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setDisabled(false);
          setShowWarning(true);
          const errors = getValidationErrors(err);
          console.log(errors);
          formRef.current?.setErrors(errors);
        }
      }
      finally{
        setDisabled(false);
      }
    },
    [InfoCSS, t, data],
  );
  const handleNPS1Text = ()=>
{
      var result;

      if(data.nps1 !== null){
        if(data.nps1 > 7) {
          result = t("nps1_label_positive");
        }
        else if(data.nps1 < 6){
          result = t("nps1_label_negative");
        }
        else{
          result = "";
        }
      }
      else{
        result = "";
      }
      return result;
    }
  const handleNPS2Text = ()=>
    {
      var result;

      if(data.nps2 !== null){
        if(data.nps2 < 6) {
          result = t("nps2_label_negative");
        }
        else{
          result = "";
        }
      }
      else{
        result = "";
      }
      return result;
    }


  const optionsQuestion1 = [
    {
      id: "1q1",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/1"
    },
    {
      id: "1q2",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/2"
    },
    {
      id: "1q3",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/3"
    },
    {
      id: "1q4",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/4"
    },
    {
      id: "1q5",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/5"
    },
    {
      id: "1q6",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/6"
    },
    {
      id: "1q7",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/7"
    },
    {
      id: "1q8",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/8"
    },
    {
      id: "1q9",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/9"
    },
    {
      id: "1q10",
      name: "q1",
      value: "/C_C_CustomerSurveyQuestion1/10"
    },
  ];

  const optionsQuestion2 = [
    {
      id: "2q1",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/1"
    },
    {
      id: "2q2",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/2"
    },
    {
      id: "2q3",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/3"
    },
    {
      id: "2q4",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/4"
    },
    {
      id: "2q5",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/5"
    },
    {
      id: "2q6",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/6"
    },
    {
      id: "2q7",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/7"
    },
    {
      id: "2q8",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/8"
    },
    {
      id: "2q9",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/9"
    },
    {
      id: "2q10",
      name: "q2",
      value: "/C_C_CustomerSurveyQuestion2/10"
    },
  ];

  return (
    <ThemeProvider theme={Theme}>
      <GlobalStyle />
      <Header size="small" toggleTheme={toggleTheme} theme={Theme} />
      <Container>
        <CSSContainer>
          {console.log({InfoCSS, Answered, Found, IsExpired, IsSuccessfull, IsError})}
          {(!isEmpty(InfoCSS) && Answered !== true && Found === true && IsExpired === false && IsSuccessfull === false && IsError === false) && (
            <>
              <Form ref={formRef} onSubmit={handleSubmit}>
                <Container>
                  <Title>{t("CSS Title")}</Title>
                  <Subtitle>{t("CSS SubTitle")}</Subtitle>

                <dataContext.Provider value={dataValue}>
                  <Section text={t("NPS1")} />

                  <SimpleRate
                    name="question1"
                    LabelName={t("Question1")}
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                    options={optionsQuestion1}
                  />
            <Accordion
              expanded={handleNPS1Text() !== ""}
              hidden={handleNPS1Text() === ""}
            >

              <AccordionSummary>
                  <Typography>{handleNPS1Text()}</Typography>
              </AccordionSummary>
              <AccordionDetails>

                  <SimpleTextArea
                    placeholder=""
                    LabelName=''
                    name="comment1"
                    defaultValue={""}
                    isFillable={true}
                    isHalf={false}
                    icon={null}
                  />
              </AccordionDetails>
            </Accordion>

                  <p className="space" />

                  <Section text={t("NPS2")} />

                  <SimpleRate
                    name="question2"
                    LabelName={t("Question2")}
                    isFillable={false}
                    isHalf={true}
                    icon={null}
                    options={optionsQuestion2}
                  />
            <Accordion
              expanded={handleNPS2Text() !== ""}
              hidden={handleNPS2Text() === ""}
            >

              <AccordionSummary>
                  <Typography>{handleNPS2Text()}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                  <SimpleTextArea
                    placeholder=""
                    LabelName=''
                    name="comment2"
                    defaultValue={""}
                    isFillable={true}
                    isHalf={false}
                    icon={null}
                  />
              </AccordionDetails>
            </Accordion>

                  <p className="space" />
                  <Section text={t("NPS3")} />

                  <SimpleTextArea
                    placeholder=""
                    name="question3"
                    LabelName={""}
                    defaultValue={""}
                    isFillable={true}
                    isHalf={false}
                    icon={null}
                  />
            </dataContext.Provider>

                  <Footer>
                    <FooterContainer>
                      <Button
                        data-sitekey="6LfrLCQcAAAAALMXjlLo4bJY3hFwnSXyIVlqEdPT"
                        data-callback='onSubmit'
                        disabled={Disabled}
                        type="submit">

                        <p hidden={Disabled}>{t("Send")}</p>
                        <p hidden={!Disabled}><CircularProgress size={12} style={{color:"white"}}/></p>
                      </Button>
                    </FooterContainer>
                    {ShowWarning && (
                      <FooterErrorContainer>
                        {t("fields_need_to_be_filled")}
                      </FooterErrorContainer>
                    )}
                  </Footer>
                    {/* <CircularProgress color={"primary"} style={{position:"fixed", top:"90vh", right:"1vw"}}/> */}
                </Container>
              </Form>
            </>)}
          {(Answered === true && IsExpired === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {t("CSSAnswered")}
                {/* {AnsCSS.CreatedBy.Name}.
                <br />
                BBT */}
              </Text>
            </Container>
          </>)}
          {(IsExpired === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <br />
              <Text>
                {/* {t("Dear")} */}
                {/* {AnsCSS.C_RelatedContact.Name} */}
                <br />
                {t("CSSExpired")}
                {/* {AnsCSS.CreatedBy.Name}. */}
                <br />
              </Text>
            </Container>
          </>)}
          {(IsSuccessfull === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="green" />
              <br />
              <Text>
                {/* {t("Dear")} */}
                {/* {AnsCSS.C_RelatedContact.Name} */}
                <br />
                {t("CSSSuccessfullySent")}
                <br />
              </Text>
            </Container>
          </>)}
          {(IsError === true) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("CSS was not sent")}</Text>
            </Container>
          </>)}
          {(Found === false) && (<>
            <Container>
              <AnimatedIcon theme={Theme} color="red" />
              <Text>{t("CCS was not found")}</Text>
            </Container>
          </>)}

        </CSSContainer>
      </Container>
    </ThemeProvider>

  );
};

export default TwoneCSS;
