import styled from 'styled-components';
import { shade } from 'polished';

import {
  Accordion as MUIAccordion,
  AccordionDetails as MUIAccordionDetails,
  AccordionSummary as MUIAccordionSummary,
  Typography,
} from "@mui/material";

export const Container = styled.div`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding: 40px 20px;
  .space{
    height: 30px;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
`;
export const Subtitle = styled.h2`
  font-weight: 500;
  font-size: 20px;
  line-height: 54px;
  text-align: center;
  color:  #7B919D;
`;

export const Text = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
  text-align: center;

  @media(max-width: 425px){
    font-size: 16px;
  }
`;

export const CSSContainer = styled.section`
  width:100%;
  padding: 14px;
  display: flex;

  form {
    width: 100%;
  }
`;

export const Footer = styled.section`
  display: flex;
  margin-top: 36px;
  align-items: flex-end;
  justify-content: flex-end;
  flex-direction: column;
`;

export const FooterContainer = styled.div`
  flex: 1;
`;

export const FooterErrorContainer = styled.p`
  flex: 1;
  color:  ${props => props.theme.colors.borderError};
  width: 190px;
  margin-top: 4px;
`;


export const Button = styled.button`
  background: ${props => props.theme.colors.secondary};
  color:  ${props => props.theme.colors.text};
  border-radius: 5px;
  padding: 15px 80px;
  border: 0;
  transition: background-color 0.2s;
  float: right;

  &:hover {
    background: ${props => shade(0.2, props.theme.colors.secondary)};
  }

  &:disabled {
    cursor: not-allowed;
    background: ${props => shade(0.2, props.theme.colors.secondary)};
  }
`;

export const Right = styled.div`
  float: left;
  width: 48%;
`;

export const Left = styled.div`
  float: left;
  width: 50%;
  margin-right: 2%;
`;

export const Accordion = styled(MUIAccordion)`
  font-color: blue;
  background-color: transparent !important;
`

export const AccordionSummary = styled(MUIAccordionSummary)`
  color:  ${props => props.theme.colors.text} !important;
  background-color: transparent !important;
`

export const AccordionDetails = styled(MUIAccordionDetails)`
  font-color: blue;
  background-color: transparent !important;
`

