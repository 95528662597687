import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface ContainerProps {
  isFocused: boolean;
  isFilled: boolean;
  isFillable: boolean;
  error: boolean;
}
interface LabelProps {
  isHalf: boolean;
}
interface Pprops {
  colorOptionalDefaul?: 'optional' | 'api';
}

export const Label = styled.label<LabelProps>`
  width: ${props => props.isHalf ? css`
    50%
  ` : css`
    11%
  `};
  word-break: break-word;
	display: flex;
	align-items: center;
	justify-content: start;


  @media(max-width: 768px){
    width: 100%
  }
`;

export const P = styled.p<Pprops>`
  color: transparent;
  ${props =>
    props.colorOptionalDefaul === 'optional' &&
    css`
    color: ${props => props.theme.colors.borderError};
  `}

  ${props =>
    props.colorOptionalDefaul === 'api' &&
    css`
    color: ${props => props.theme.colors.borderActive};
  `}
`


export const ContainerField = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
	align-items: center;
	justify-content: start;


  @media(max-width: 768px){
    flex-direction: column;
  }
`;

export const Container = styled.div<ContainerProps>`
  background: ${props => props.theme.colors.inputBackgroundFillable};
  border-radius: 10px;
  padding: 8px;
  width: 100%;
  justify-content: center;

  border: 2px solid ${props => props.theme.colors.borderInactive};
  color: ${props => props.theme.colors.text};

  display: flex;
  align-items: center;


  @media(max-width: 768px){
    flex-wrap: wrap;
  }

  ${props =>
    props.error &&
    css`
      border: 2px solid ${props => props.theme.colors.borderError};
    `}

  ${props =>
    props.isFocused &&
    css`
      border: 2px solid ${props => props.theme.colors.borderActive};
      color: ${props => props.theme.colors.borderActive};
    `}

  ${props =>
    props.isFilled &&
    css`
      color: ${props => props.theme.colors.borderActive};
    `}


  ${props =>
    !props.isFillable &&
    css`
      background: ${props => props.theme.colors.inputBackground};
      border: 0px;
    `}


  input {
    flex: 1;
    background: transparent;
    border: 0;
    color: ${props => props.theme.colors.textInsideInput};

    ${props =>
    !props.isFillable &&
    css`
        color: ${props => props.theme.colors.text};
      `}

    &::placeholder {
      color: ${props => props.theme.colors.textInsideInput};

      ${props =>
    !props.isFillable &&
    css`
          color: ${props => props.theme.colors.text};
        `}
    }

    &:disabled {
      cursor: not-allowed;
    }
  }


    .red .radioLabel{
      display: block;
      float: left;
      padding: 5px;
      margin: 5px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      color: white;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      border: 2px solid #BA1E28;
      border-radius: 5px;
      background: #DA1E28;

      @media(max-width: 768px){
        margin-left: 10px;
      }

        &:hover {
          background: #BA1E28;
        }
  }

    .yellow .radioLabel{
      display: block;
      float: left;
      padding: 5px;
      margin: 5px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      color: white;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      border: 2px solid #E2D000;
      border-radius: 5px;
      background: #F6E600;

      @media(max-width: 768px){
        margin-left: 10px;
      }

        &:hover {
          background: #E2D000;
        }
  }
    .green .radioLabel{
      display: block;
      float: left;
      padding: 5px;
      margin: 5px;
      margin-left: 20px;
      width: 30px;
      height: 30px;
      color: white;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      border: 2px solid #27B66D;
      border-radius: 5px;
      background: #14DA79;

      @media(max-width: 768px){
        margin-left: 10px;
      }

        &:hover {
          background: #27B66D;
        }
  }

  input[type="radio"]{
    display: none;

  }
  .red input[type="radio"]:checked+label{
          background: #8A1E28;
  }
  .yellow input[type="radio"]:checked+label{
          background:  #C6AE00;
  }
  .green input[type="radio"]:checked+label{
          background:  #009B55;
  }

  svg {
    margin-right: 16px;
  }
`;




export const Error = styled(Tooltip)`
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: ${props => props.theme.colors.borderError};
    color: #fff;

    &:before {
      border-color: ${props => props.theme.colors.borderError} transparent;
    }
  }
`;
